import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import HeroImage from '../components/contentful/hero-image'
import { usStates, buildBreadcrumbsItem } from '../helpers'
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Carousel from 'react-bootstrap/Carousel'
import styles from '../scss/page.scss'

class AffiliatesPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Affiliate Program | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'If you would like to become an Honest Paws Affiliate, and spread the word about our products, please click here to apply'
      }
    }
    
    this.breadcrumbs = [
      buildBreadcrumbsItem('Affiliate Program', this.props.location.href)
    ]

    this.ctaLink = 'https://one.pet/pages/affiliate-sign-up'
    
    this.faqsIcons = get(this, 'props.data.faqsIcons.edges')
    this.faqsTiles = [
      {
        image: this.faqsIcons[0].node.childImageSharp,
        title: 'Competitive Comission Rates',
        description: 'We currently offer Revshare and CPA options with premium payouts.',
      },
      {
        image: this.faqsIcons[1].node.childImageSharp,
        title: 'Product Specific Creatives',
        description: 'Whether its banners, email creatives, product shots or lifestyle images, we have your creative needs covered!',
      },
      {
        image: this.faqsIcons[2].node.childImageSharp,
        title: '30-Day Cookie',
        description: 'Your unique tracking link places a cookie on the device of each user you send to HonestPaws.com. Anytime these visitors return to purchase from our site within this period, you receive credit for the sale! No coupon code required.',
      },
      {
        image: this.faqsIcons[3].node.childImageSharp,
        title: 'Frequent Affiliate Communications',
        description: 'Our Affiliates receive consistent newsletters and personal correspondence about upcoming promotions and optimization recommendations. Keeping you in the know and in-line to maximize your profits.',
      },
    ]
    
    this.slidesIcons = get(this, 'props.data.slidesIcons.edges')
    this.slides = [
      {
        image: this.slidesIcons[0].node.childImageSharp,
        title: 'Free Delivery On Eligible Orders',
      },
      {
        image: this.slidesIcons[1].node.childImageSharp,
        title: 'Exclusive Discount Codes',
      },
      {
        image: this.slidesIcons[2].node.childImageSharp,
        title: 'Special Promotions',
      }
    ]
  }
  
  render() {
    return (
      <Layout containerClassName={`page page-affiliates`} location={this.props.location} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Honest Paws Affiliate Program</h1>
            </div>
          </div>
        </section>
        <section className="section-description">
          <div className="container">
            <div className="row"> 
              <div className="col-12 col-lg-10 offset-lg-1 text-center px-lg-6">
                <h2 className="font-avenir">Earn up to <span className="text-success">25%</span> commission on valid sales.</h2>
                <p>
                  Through the Honest Paws Affiliate Program, selected websites/partners can earn commission on sales generated through unique affiliate links.
                </p>
                <p> 
  If your application is accepted, you’ll have an extensive range of Honest Paws links, pages and banners, that can be used to promote our products.
                </p>
              </div>
              <div className="col-12 col-lg-10 offset-lg-1 text-center px-lg-6">
                <IoIosArrowDown title="Arrow Down"/>
              </div>
              <div className="col-12 col-lg-10 offset-lg-1 text-center px-lg-6">
                <a className="btn btn-success" href={this.ctaLink} target="_blank">Apply Now</a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-faqs">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 text-center mb-5 mb-lg-6">
                <h2 className="font-avenir">Frequently Asked Questions</h2>
                <h3 className="font-avenir">What are the benefits of Honest Paws’ Affiliate Program?</h3>
                <div>Once accepted into Honest Paws Affiliate Program, you can benefit from the following Perks:</div>
              </div>
              { this.faqsTiles.map(({ image, title, description }, i) => {
                return (
                  <div className={`col-12 col-lg-6 ${i%2 === 0 ? 'pl-lg-6' : 'pr-lg-6'} ${i > 1 && 'with-margin'}`} key={i}>
                    <div className="faq-tile">
                      <Img fixed={ image.fixed } alt={title} />
                      <h4>{title}</h4>
                      <p>{description}</p>
                    </div>
                  </div>
                )
              } )}
              <div className="col-12 col-lg-10 offset-lg-1 text-center">
                <a className="btn btn-success" href={this.ctaLink} target="_blank">Apply Now</a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-who bg-gray-light-medium">
          <div className="container">
            <div className="row px-lg-6">
              <div className="col-12">
                <div className="shadow bg-white">
                  <div className="rounded-circle rounded-circle-big"></div>
                  <div className="rounded-circle rounded-circle-small"></div>
                  <div className="row">
                    <div className="col col-lg-6 offset-lg-6 checkmarks pr-lg-0">
                      <h2 className="font-avenir">Who can become an <br className="d-none d-lg-block"/>Honest Paws affiliate?</h2>
                      <div>We've partnered with:</div>
                      <ul>
                        <li>Review Sites</li>
                        <li>Cannabis/Hemp Sites</li>
                        <li>Premium Media Buys and News Sites</li>
                        <li>Health and Wellness Sites</li>
                        <li>Paid Media Publishers</li>
                        <li>Pet Shopping Directories</li>
                        <li>Bloggers</li>
                      </ul>
                      <a className="btn btn-success" href={this.ctaLink} target="_blank">Apply Now</a>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </section>
        <section className="section-customers bg-yellow-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2 text-center mb-5 mb-lg-6 p-lg-0">
                <h2 className="font-avenir px-lg-3">How will my customers benefit from me joining the Honest Paws Affiliate Program?</h2>
                <div>Once you become an Affiliate partner, you can offer your customers the following:</div>
              </div>
            </div>
            <div className="row text-center slides">
              <div className="col-8 offset-lg-2 d-none d-lg-flex">
                {
                  this.slides.map(({ image, title }, i) => {
                    return (
                      <div className={`col-4`}>
                        <Img fixed={image.fixed} alt={title} />
                        <div className="title">{title}</div>
                      </div>
                    )
                  })
                }
              </div>
              <Carousel className="col-12 d-lg-none" interval={3000} nextIcon={<IoIosArrowForward title="Arrow Forward" />} prevIcon={<IoIosArrowBack title="Arrow Back"/>}>
                {
                  this.slides.map(({ image, title }, i) => {
                    return (
                      <Carousel.Item key={i}> 
                        <Img fixed={image.fixed} alt={title} />
                        <div className="title">{title}</div>
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel> 
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <a className="btn btn-success" href={this.ctaLink} target="_blank">Apply Now</a>
              </div>
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}

export default AffiliatesPage

export const AffiliatesPagePageQuery = graphql`
  query AffiliatesPagePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    faqsIcons: allFile(filter: {relativeDirectory: {eq: "affiliates/faqs"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 64) {
              ...GatsbyImageSharpFluid
            }      
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    slidesIcons: allFile(filter: {relativeDirectory: {eq: "affiliates/slides"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 64) {
              ...GatsbyImageSharpFluid
            }      
            fixed(width: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
